import { FC } from 'react';

import { Typography } from '@letsdance/ui-kit';
import { CheckIcon } from '@widgets/Landing/ui/icons';
import cls from '@widgets/Landing/ui/MainUi/Benefit/styles.module.scss';

interface BenefitMessagesProps {
  messages: string[];
}

export const BenefitMessages: FC<BenefitMessagesProps> = ({ messages }) => (
  <>
    {messages.map((message) => (
      <div key={message} className={cls.benefit__item}>
        <CheckIcon className={cls.benefit__icon} />
        <Typography variant={'body-18'} className={cls.benefit__text}>
          {message}
        </Typography>
      </div>
    ))}
  </>
);
