import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const LandingLogoIcon: FC<IconProps> = ({
  height = 66,
  width = 346,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 346 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M149.452 65.2415H131.105V13.4596H149.604C154.81 13.4596 159.291 14.4963 163.048 16.5696C166.805 18.626 169.695 21.5842 171.716 25.4443C173.755 29.3043 174.774 33.9229 174.774 39.3C174.774 44.6939 173.755 49.3293 171.716 53.2062C169.695 57.0831 166.788 60.0582 162.998 62.1315C159.224 64.2048 154.709 65.2415 149.452 65.2415ZM142.048 55.8611H148.997C152.232 55.8611 154.953 55.288 157.16 54.1418C159.384 52.9787 161.052 51.1835 162.164 48.7562C163.293 46.3121 163.857 43.16 163.857 39.3C163.857 35.4737 163.293 32.3469 162.164 29.9196C161.052 27.4923 159.392 25.7056 157.185 24.5593C154.978 23.4131 152.257 22.84 149.023 22.84H142.048V55.8611Z"
      fill="#404040"
    />
    <path
      d="M193.746 65.9747C191.27 65.9747 189.063 65.5449 187.125 64.6852C185.188 63.8087 183.655 62.5192 182.526 60.8168C181.414 59.0974 180.858 56.9567 180.858 54.3946C180.858 52.237 181.254 50.425 182.046 48.9585C182.838 47.492 183.916 46.3121 185.281 45.4187C186.645 44.5254 188.195 43.8511 189.93 43.396C191.683 42.9409 193.519 42.6206 195.44 42.4352C197.697 42.1992 199.517 41.9801 200.898 41.7778C202.28 41.5587 203.282 41.2384 203.905 40.817C204.529 40.3956 204.84 39.7719 204.84 38.946V38.7943C204.84 37.193 204.335 35.9541 203.324 35.0775C202.33 34.201 200.915 33.7628 199.079 33.7628C197.141 33.7628 195.6 34.1926 194.454 35.0523C193.308 35.8951 192.55 36.957 192.18 38.238L182.223 37.429C182.728 35.0691 183.722 33.0295 185.205 31.3102C186.687 29.574 188.6 28.2424 190.941 27.3153C193.3 26.3714 196.029 25.8994 199.129 25.8994C201.286 25.8994 203.349 26.1522 205.321 26.6579C207.309 27.1636 209.069 27.9474 210.602 29.0093C212.152 30.0713 213.374 31.4366 214.267 33.1054C215.16 34.7573 215.606 36.7379 215.606 39.0471V65.2415H205.396V59.856H205.093C204.47 61.0696 203.636 62.14 202.591 63.067C201.547 63.9773 200.292 64.6937 198.826 65.2162C197.36 65.7219 195.667 65.9747 193.746 65.9747ZM196.83 58.5412C198.413 58.5412 199.812 58.2294 201.025 57.6057C202.238 56.9651 203.189 56.1055 203.88 55.0267C204.571 53.9479 204.916 52.7258 204.916 51.3605V47.2392C204.579 47.4583 204.116 47.6606 203.526 47.846C202.954 48.0146 202.305 48.1747 201.581 48.3264C200.856 48.4613 200.132 48.5877 199.407 48.7057C198.683 48.8068 198.026 48.8995 197.436 48.9838C196.172 49.1692 195.069 49.4642 194.125 49.8687C193.182 50.2733 192.449 50.8211 191.927 51.5122C191.405 52.1865 191.143 53.0293 191.143 54.0406C191.143 55.5071 191.674 56.628 192.736 57.4034C193.814 58.1619 195.178 58.5412 196.83 58.5412Z"
      fill="#404040"
    />
    <path
      d="M234.724 42.7892V65.2415H223.958V26.4051H234.218V33.2571H234.673C235.532 30.9984 236.973 29.2116 238.995 27.8968C241.016 26.5652 243.468 25.8994 246.348 25.8994C249.044 25.8994 251.394 26.4894 253.399 27.6693C255.404 28.8492 256.962 30.5348 258.074 32.7261C259.186 34.9005 259.742 37.4964 259.742 40.5136V65.2415H248.977V42.4352C248.993 40.0585 248.387 38.2043 247.157 36.8727C245.927 35.5242 244.234 34.85 242.078 34.85C240.629 34.85 239.348 35.1618 238.236 35.7855C237.141 36.4092 236.282 37.3194 235.659 38.5162C235.052 39.6961 234.741 41.1204 234.724 42.7892Z"
      fill="#404040"
    />
    <path
      d="M285.765 66C281.789 66 278.369 65.1572 275.505 63.4716C272.658 61.7691 270.468 59.4093 268.935 56.392C267.418 53.3748 266.66 49.9025 266.66 45.975C266.66 41.997 267.427 38.5077 268.96 35.5074C270.51 32.4901 272.708 30.1387 275.556 28.4531C278.403 26.7506 281.789 25.8994 285.715 25.8994C289.101 25.8994 292.066 26.5147 294.61 27.7451C297.154 28.9756 299.167 30.7034 300.65 32.9284C302.132 35.1534 302.95 37.7661 303.101 40.7665H292.942C292.656 38.828 291.898 37.2688 290.668 36.0889C289.455 34.8921 287.863 34.2937 285.892 34.2937C284.224 34.2937 282.766 34.7488 281.52 35.6591C280.29 36.5524 279.329 37.8588 278.639 39.5781C277.948 41.2974 277.603 43.3792 277.603 45.8233C277.603 48.3011 277.94 50.4081 278.613 52.1443C279.304 53.8805 280.273 55.2037 281.52 56.1139C282.766 57.0241 284.224 57.4793 285.892 57.4793C287.121 57.4793 288.225 57.2264 289.202 56.7207C290.196 56.215 291.013 55.4818 291.653 54.521C292.31 53.5434 292.74 52.3719 292.942 51.0065H303.101C302.933 53.9732 302.124 56.5859 300.675 58.8446C299.243 61.0865 297.264 62.8395 294.736 64.1037C292.209 65.3679 289.219 66 285.765 66Z"
      fill="#404040"
    />
    <path
      d="M327.684 66C323.691 66 320.254 65.1909 317.373 63.5727C314.509 61.9377 312.302 59.6284 310.752 56.6449C309.202 53.6445 308.427 50.0963 308.427 46.0003C308.427 42.0054 309.202 38.4993 310.752 35.4821C312.302 32.4648 314.484 30.1134 317.297 28.4278C320.128 26.7422 323.447 25.8994 327.254 25.8994C329.815 25.8994 332.199 26.3124 334.406 27.1383C336.63 27.9474 338.567 29.1695 340.218 30.8045C341.886 32.4396 343.184 34.496 344.11 36.9738C345.037 39.4348 345.5 42.3172 345.5 45.621V48.5793L319.066 48.6045V42.2582L335.366 41.9042C335.366 40.3535 335.029 38.9797 334.355 37.7829C333.682 36.5862 332.746 35.6506 331.55 34.9764C330.371 34.2853 328.998 33.9398 327.431 33.9398C325.797 33.9398 324.348 34.319 323.084 35.0775C321.838 35.8192 320.861 36.8221 320.153 38.0863C319.445 39.3337 319.083 40.7243 319.066 42.2582V48.6045C319.066 50.5261 319.42 52.1865 320.128 53.5855C320.852 54.9846 321.871 56.0633 323.186 56.8219C324.5 57.5804 326.058 57.9597 327.861 57.9597C329.057 57.9597 330.152 57.7911 331.146 57.454C332.14 57.1169 332.991 56.6112 333.698 55.9369C334.406 55.2627 334.945 54.4367 335.316 53.4591L345.273 54.1165C344.767 56.51 343.731 58.6002 342.164 60.3869C340.614 62.1568 338.609 63.539 336.15 64.5335C333.707 65.5112 330.885 66 327.684 66Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.9425 14.0155V34.2443C32.2613 33.3909 27.9478 32.2713 22.8528 32.2713C17.7578 32.2713 13.3267 33.2407 10.8697 34.2244V14.0155H0.5V64.8573H10.8697V43.6248C14.2602 42.6565 19.3148 42.0927 22.8528 42.0927C26.4208 42.0927 31.5267 42.6668 34.9425 43.6447V64.8573H45.3174V14.0155H34.9425Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.744 32.4261C115.744 10.7704 102.405 0 84.2165 0H0.500061V9.81175H81.6782C96.1604 9.81175 105.391 16.8858 105.391 32.4261C105.391 47.9562 96.1604 55.0399 81.6782 55.0399H59.5086C64.0412 49.1361 73.6808 46.0298 79.1699 40.8986C82.4014 37.8782 85.0001 33.8365 85.0001 28.3389C85.0001 23.016 82.5177 18.9044 78.902 16.2628C75.4585 13.7459 72.1463 12.6843 68.2125 12.6843C58.6403 12.6843 48.5472 19.5621 48.5472 32.4358H58.8829C58.8829 26.0314 63.4592 22.4915 68.2125 22.4915C71.3955 22.4915 74.665 24.9658 74.665 28.3441C74.665 33.1157 68.5426 36.849 64.9153 38.9699C56.8667 43.7302 48.6739 49.5014 48.6739 59.7632L48.5777 64.8517H81.673C102.658 64.8517 115.744 54.0813 115.744 32.4261Z"
      fill="#FF2F6C"
    />
  </svg>
);
