import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const ArrowRightIcon: FC<IconProps> = ({
  height = 16,
  width = 16,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M5 14L10.5118 9.06061C11.1627 8.47728 11.1627 7.52274 10.5118 6.9394L5 2"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
