import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const PlayIcon: FC<IconProps> = ({ height = 72, width = 72 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="-6.10352e-05" width="72" height="72" rx="36" fill="#FF2F6C" />
    <path
      d="M48.5 33.4019C50.5 34.5566 50.5 37.4434 48.5 38.5981L32 48.1244C30 49.2791 27.5 47.8357 27.5 45.5263L27.5 26.4737C27.5 24.1643 30 22.7209 32 23.8756L48.5 33.4019Z"
      fill="white"
    />
  </svg>
);
