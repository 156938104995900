import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const CheckIcon: FC<IconProps> = ({
  height = 24,
  width = 24,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M5 11.5L10.625 17L20 6"
      stroke="#FF2F6C"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
