import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const TableLandingLogo: FC<IconProps> = ({
  height = 62,
  width = 320,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 320 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M138.195 60.3984H121.195V12.4431H138.336C143.159 12.4431 147.312 13.4031 150.793 15.3232C154.274 17.2277 156.951 19.9673 158.824 23.5421C160.713 27.1169 161.658 31.3942 161.658 36.3739C161.658 41.3693 160.713 45.6622 158.824 49.2526C156.951 52.843 154.258 55.5982 150.746 57.5183C147.249 59.4384 143.066 60.3984 138.195 60.3984ZM131.334 51.7112H137.774C140.771 51.7112 143.292 51.1805 145.337 50.1189C147.397 49.0418 148.943 47.3793 149.973 45.1314C151.019 42.8679 151.542 39.9487 151.542 36.3739C151.542 32.8304 151.019 29.9346 149.973 27.6867C148.943 25.4388 147.405 23.7841 145.36 22.7226C143.315 21.6611 140.794 21.1303 137.797 21.1303H131.334V51.7112Z"
      fill="#404040"
    />
    <path
      d="M179.237 61.0775C176.942 61.0775 174.897 60.6794 173.102 59.8833C171.307 59.0715 169.886 57.8773 168.84 56.3007C167.81 54.7084 167.295 52.7259 167.295 50.3531C167.295 48.355 167.662 46.6768 168.396 45.3187C169.129 43.9606 170.128 42.8679 171.393 42.0405C172.657 41.2132 174.093 40.5888 175.701 40.1673C177.325 39.7458 179.026 39.4492 180.806 39.2775C182.898 39.0589 184.584 38.856 185.864 38.6687C187.144 38.4657 188.073 38.1691 188.65 37.7789C189.228 37.3886 189.516 36.811 189.516 36.0461V35.9056C189.516 34.4226 189.048 33.2753 188.112 32.4635C187.191 31.6518 185.879 31.2459 184.178 31.2459C182.383 31.2459 180.954 31.644 179.893 32.4401C178.831 33.2206 178.129 34.2041 177.785 35.3905L168.559 34.6412C169.028 32.4557 169.949 30.5668 171.322 28.9746C172.696 27.3667 174.468 26.1335 176.638 25.2749C178.823 24.4007 181.352 23.9636 184.225 23.9636C186.223 23.9636 188.135 24.1978 189.961 24.6661C191.803 25.1344 193.435 25.8603 194.855 26.8437C196.291 27.8272 197.423 29.0917 198.251 30.6371C199.078 32.1669 199.492 34.0011 199.492 36.1398V60.3984H190.032V55.4109H189.751C189.173 56.5349 188.4 57.5261 187.432 58.3847C186.465 59.2277 185.302 59.8911 183.944 60.375C182.585 60.8433 181.017 61.0775 179.237 61.0775ZM182.094 54.1933C183.561 54.1933 184.857 53.9045 185.981 53.3269C187.105 52.7337 187.987 51.9376 188.627 50.9385C189.267 49.9394 189.587 48.8077 189.587 47.5432V43.7265C189.275 43.9294 188.845 44.1167 188.299 44.2884C187.768 44.4445 187.167 44.5928 186.496 44.7333C185.825 44.8582 185.153 44.9753 184.482 45.0846C183.811 45.1782 183.202 45.2641 182.656 45.3421C181.485 45.5139 180.462 45.787 179.588 46.1617C178.714 46.5363 178.035 47.0437 177.551 47.6837C177.067 48.3081 176.825 49.0887 176.825 50.0253C176.825 51.3834 177.317 52.4215 178.3 53.1396C179.299 53.842 180.564 54.1933 182.094 54.1933Z"
      fill="#404040"
    />
    <path
      d="M217.206 39.6053V60.3984H207.23V24.4319H216.737V30.7776H217.159C217.955 28.6858 219.29 27.0311 221.163 25.8135C223.036 24.5802 225.307 23.9636 227.977 23.9636C230.474 23.9636 232.652 24.51 234.51 25.6027C236.367 26.6954 237.811 28.2565 238.842 30.2859C239.872 32.2996 240.387 34.7036 240.387 37.4979V60.3984H230.412V39.2775C230.428 37.0764 229.866 35.3593 228.726 34.126C227.586 32.8772 226.018 32.2528 224.02 32.2528C222.677 32.2528 221.491 32.5416 220.46 33.1192C219.446 33.6967 218.65 34.5397 218.072 35.648C217.51 36.7408 217.221 38.0599 217.206 39.6053Z"
      fill="#404040"
    />
    <path
      d="M264.499 61.1009C260.815 61.1009 257.646 60.3204 254.993 58.7593C252.354 57.1827 250.325 54.9972 248.905 52.2029C247.5 49.4087 246.797 46.1929 246.797 42.5557C246.797 38.8716 247.507 35.6402 248.928 32.8616C250.364 30.0673 252.401 27.8896 255.039 26.3286C257.678 24.7519 260.815 23.9636 264.453 23.9636C267.59 23.9636 270.338 24.5334 272.695 25.673C275.052 26.8125 276.918 28.4126 278.291 30.4732C279.665 32.5338 280.422 34.9534 280.563 37.732H271.149C270.884 35.9368 270.182 34.4929 269.042 33.4001C267.918 32.2918 266.443 31.7376 264.616 31.7376C263.071 31.7376 261.721 32.1591 260.566 33.0021C259.426 33.8294 258.536 35.0392 257.896 36.6315C257.256 38.2238 256.936 40.1517 256.936 42.4152C256.936 44.7099 257.248 46.6612 257.873 48.2691C258.513 49.877 259.41 51.1024 260.566 51.9454C261.721 52.7883 263.071 53.2098 264.616 53.2098C265.756 53.2098 266.779 52.9757 267.684 52.5073C268.605 52.039 269.362 51.36 269.955 50.4702C270.564 49.5648 270.962 48.4798 271.149 47.2154H280.563C280.406 49.9628 279.657 52.3825 278.315 54.4743C276.988 56.5505 275.154 58.1739 272.812 59.3447C270.47 60.5155 267.7 61.1009 264.499 61.1009Z"
      fill="#404040"
    />
    <path
      d="M303.34 61.1009C299.641 61.1009 296.456 60.3516 293.787 58.853C291.133 57.3388 289.088 55.2002 287.652 52.4371C286.216 49.6584 285.497 46.3724 285.497 42.5791C285.497 38.8794 286.216 35.6324 287.652 32.8382C289.088 30.0439 291.109 27.8662 293.716 26.3052C296.339 24.7441 299.414 23.9636 302.942 23.9636C305.315 23.9636 307.524 24.3461 309.569 25.111C311.629 25.8603 313.425 26.992 314.954 28.5063C316.5 30.0205 317.702 31.925 318.56 34.2197C319.419 36.4988 319.848 39.1682 319.848 42.2279V44.9675H289.478V38.7857H310.459C310.459 37.3496 310.146 36.0773 309.522 34.969C308.897 33.8606 308.031 32.9943 306.923 32.3699C305.83 31.7298 304.558 31.4098 303.106 31.4098C301.592 31.4098 300.249 31.761 299.079 32.4635C297.923 33.1504 297.018 34.0792 296.362 35.25C295.707 36.4052 295.371 37.693 295.355 39.1136V44.9909C295.355 46.7705 295.683 48.3081 296.339 49.6038C297.01 50.8995 297.955 51.8985 299.172 52.601C300.39 53.3035 301.834 53.6547 303.504 53.6547C304.612 53.6547 305.627 53.4986 306.548 53.1864C307.469 52.8742 308.257 52.4059 308.913 51.7815C309.569 51.157 310.068 50.3921 310.412 49.4867L319.638 50.0955C319.169 52.3122 318.209 54.2479 316.757 55.9026C315.321 57.5417 313.464 58.8218 311.184 59.7428C308.921 60.6482 306.306 61.1009 303.34 61.1009Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4136 12.9844V31.7184C29.9293 30.928 25.9324 29.8911 21.2116 29.8911C16.4907 29.8911 12.3849 30.7889 10.1083 31.6999V12.9844H0.5V60.0691H10.1083V40.4057C13.2499 39.5089 17.9333 38.9868 21.2116 38.9868C24.5176 38.9868 29.2486 39.5184 32.4136 40.4241V60.0691H42.0267V12.9844H32.4136Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.283 30.0299C107.283 9.9745 94.9226 0 78.0696 0H0.5V9.0867H75.7177C89.1366 9.0867 97.6893 15.638 97.6893 30.0299C97.6893 44.4124 89.1366 50.9726 75.7177 50.9726H55.1759C59.3757 45.5051 68.3075 42.6283 73.3936 37.8763C76.3878 35.0791 78.7957 31.3361 78.7957 26.2448C78.7957 21.3152 76.4956 17.5074 73.1453 15.0611C69.9547 12.7301 66.8857 11.7469 63.2407 11.7469C54.3713 11.7469 45.0193 18.1165 45.0193 30.0389H54.5961C54.5961 24.1077 58.8364 20.8294 63.2407 20.8294C66.19 20.8294 69.2195 23.1209 69.2195 26.2495C69.2195 30.6685 63.5466 34.126 60.1856 36.0902C52.7279 40.4987 45.1368 45.8434 45.1368 55.3469L45.0476 60.0593H75.7129C95.157 60.0593 107.283 50.0848 107.283 30.0299Z"
      fill="#FF2F6C"
    />
  </svg>
);
