import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import { Button, Typography } from '@letsdance/ui-kit';
import { Instagram, Telegram } from '@widgets/Landing/ui/icons';

import cls from './styles.module.scss';

export const Footer: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <footer className={cls.footer}>
      <div className={cls.footer__wrapper}>
        <div className={cls.footer__headers}>
          <Typography
            variant={isDesktop ? 'head-56' : 'head-32'}
            className={cls.footer__title}>
            Остались вопросы?
          </Typography>
          <Typography variant={isDesktop ? 'body-24' : 'body-18'}>
            <a
              href="https://t.me/panichm"
              target="_blank"
              rel="noreferrer"
              className={cls.footer__link}>
              <b>Свяжитесь с нами</b>
            </a>{' '}
            и мы ответим {!isDesktop && <br />} на все возникшие вопросы.
          </Typography>
        </div>
        <div className={cls.footer__controls}>
          <a
            href="https://t.me/h2dance_bot"
            target="_blank"
            rel="noreferrer"
            className={cls.footer__telegram}>
            <Telegram className={cls.footer__icon} />
          </a>
          <a
            href="https://www.instagram.com/h2.dance"
            target="_blank"
            className={cls.footer__instagram}
            rel="noreferrer">
            <Instagram className={cls.footer__icon} />
          </a>
          <Button
            size="large"
            color={'secondary'}
            className={cls.footer__btn}
            onClick={openSupportTg}>
            Задать вопрос
          </Button>
        </div>
      </div>
      <div className={cls.footer__bottom}>
        <div>
          <Typography variant={'body-16'} tag={'div'}>
            <a
              href="/docs/policy.pdf"
              target="_blank"
              className={cls.footer__policy}>
              Политика конфиденциальности
            </a>
            <a
              href="/docs/oferta.pdf"
              target="_blank"
              className={cls.footer__policy}>
              Публичная оферта
            </a>
          </Typography>
        </div>
        <Typography variant={'body-16'} className={cls.footer__policy}>
          © 2024 H2Dance. Все права защищены
        </Typography>
      </div>
    </footer>
  );
};
