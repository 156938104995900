import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { Button, Typography } from '@letsdance/ui-kit';
import { onTeachersList } from '@shared/libs/utils/link';
import { LandingLogoIcon } from '@shared/ui/icons/LandingLogo';

import cls from './styles.module.scss';

export const MainScreenLanding: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <div className={cls.section__group}>
        <div className={cls['section__main-desc']}>
          <div className={cls.section__description}>
            <LandingLogoIcon />
            <div className={cls.section__title}>
              <Typography
                variant={isDesktop ? 'head-32' : 'head-24'}
                className={cls.section__subtitle}
                color={'on-surface-primary-1'}>
                Платформа для обучения танцам в Telegram
              </Typography>
              <Typography
                variant={'body-24'}
                color={'on-surface-primary-1'}
                className={cls.section__subtitle_small}>
                Изучай танцы в любое время, где угодно с лучшими
                преподавателями!
              </Typography>
            </div>
          </div>
          <div className={cls.section__controls}>
            <Button
              fullWidth={!isDesktop}
              className={cx(cls.btn, cls.btn_reg)}
              onClick={() =>
                window.open(
                  'https://t.me/h2dance_bot',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              size={'large'}>
              Начать обучение
            </Button>
            <Button
              variant={'outlined'}
              onClick={onTeachersList}
              size={'large'}
              color={'secondary'}
              className={cls.btn}>
              Регистрация преподавателя
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
