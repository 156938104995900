import { FC, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button, Divider, Typography } from '@letsdance/ui-kit';
import { PlayIcon } from '@shared/ui/icons/PlayIcon';
import { MESSAGES_WIKI } from '@widgets/Landing/const/main-text.const';
import {
  ArrowRightIcon,
  OriginalTelegramLogo,
} from '@widgets/Landing/ui/icons';
import { BenefitMessages } from '@widgets/Landing/ui/MainUi/Benefit/BenefitMessages';

import cls from './styles.module.scss';

export const Benefit: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });
  const videoPlayer = useRef<HTMLVideoElement | null>(null);

  const [isPaused, setPaused] = useState<boolean>(true);

  const playVideo = async () => {
    setPaused(true);

    if (videoPlayer.current) {
      await videoPlayer.current.play();

      setPaused(videoPlayer.current.paused);
    }
  };

  return (
    <section className={cls.benefit}>
      <Typography
        variant={isDesktop ? 'head-56' : 'head-32'}
        className={cls.benefit__title}>
        Преимущества <br /> и возможности
      </Typography>
      <div className={cls.benefit__wrapper}>
        <div>
          <div className={cls.benefit__card}>
            <div className={cls.benefit__group}>
              <div className={cls.benefit__iconEmoji}>
                {MESSAGES_WIKI.wiki.icon}
              </div>
              <Typography variant={'head-24'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: MESSAGES_WIKI.wiki.title || '',
                  }}></span>
              </Typography>
            </div>
            <Divider className={cls.benefit__divider} />
            <div className={cls.benefit__messengers}>
              <BenefitMessages messages={MESSAGES_WIKI.wiki.messages} />
              <Divider className={cls.benefit__divider} />
              <Button
                variant={'text'}
                size={'large'}
                className={cls.benefit__btn}
                onClick={() =>
                  window.open(
                    'https://t.me/h2dance_bot',
                    '_blank',
                    'noopener,noreferrer',
                  )
                }>
                Перейти в Wikipedia <ArrowRightIcon />
              </Button>
            </div>
          </div>
          <div className={cls.benefit__card}>
            <div className={cls.benefit__group}>
              <div className={cls.benefit__iconEmoji}>
                {MESSAGES_WIKI.availability.icon}
              </div>
              <Typography variant={'head-24'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: MESSAGES_WIKI.availability.title || '',
                  }}></span>
              </Typography>
            </div>
            <Divider className={cls.benefit__divider} />
            <div className={cls.benefit__messengers}>
              <BenefitMessages messages={MESSAGES_WIKI.availability.messages} />
            </div>
          </div>
        </div>
        <div className={cls.benefit__videoContainer}>
          <video
            ref={videoPlayer}
            loop
            preload="auto"
            playsInline
            className={cls.benefit__video}
            poster="/phone_poster.png">
            <source src="/video.mp4" type="video/mp4" />
            <track kind="captions" />
          </video>
          {isPaused && (
            <button className={cls.benefit__videoButton} onClick={playVideo}>
              <PlayIcon />
            </button>
          )}
        </div>
        <div>
          <div className={cls.benefit__card}>
            <div className={cls.benefit__group}>
              <div className={cls.benefit__iconEmoji}>
                {MESSAGES_WIKI.cup.icon}
              </div>
              <Typography variant={'head-24'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: MESSAGES_WIKI.cup.title || '',
                  }}></span>
              </Typography>
            </div>
            <Divider className={cls.benefit__divider} />
            <div className={cls.benefit__messengers}>
              <BenefitMessages messages={MESSAGES_WIKI.cup.messages} />
              <Divider className={cls.benefit__divider} />
              <Button
                variant={'text'}
                size={'large'}
                className={cls.benefit__btn}
                onClick={() =>
                  window.open(
                    'https://t.me/h2dance_bot',
                    '_blank',
                    'noopener,noreferrer',
                  )
                }>
                Перейти в Wikipedia <ArrowRightIcon />
              </Button>
            </div>
          </div>
          <div className={cls.benefit__card}>
            <div className={cls.benefit__group}>
              <OriginalTelegramLogo />
              <Typography variant={'head-24'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: MESSAGES_WIKI.telegram.title || '',
                  }}></span>
              </Typography>
            </div>
            <Divider className={cls.benefit__divider} />
            <div className={cls.benefit__messengers}>
              <BenefitMessages messages={MESSAGES_WIKI.telegram.messages} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
