import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button, Divider, Typography } from '@letsdance/ui-kit';
import { MobileLandingLogo } from '@shared/ui/icons/MobileLandingLogo';
import { TableLandingLogo } from '@shared/ui/icons/TableLandingLogo';
import { QUESTION_CARD } from '@widgets/Landing/const/main-text.const';
import { ArrowRightIcon, CheckIcon } from '@widgets/Landing/ui/icons';

import cls from './styles.module.scss';

export const Question: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <header className={cls.header}>
        <Typography
          variant={isDesktop ? 'head-56' : 'head-32'}
          className={cls.title}>
          Кому подойдет{' '}
          {isDesktop ? <TableLandingLogo /> : <MobileLandingLogo />}
        </Typography>
        <Typography
          variant={isDesktop ? 'body-24' : 'body-18'}
          className={cls.text}>
          H2dance — платформа, которая прекрасно подходит для всех танцоров,
          {isDesktop && <br />} от тех, кто никогда не танцевал, до тех, кто уже
          давно в теме
        </Typography>
        <Button variant={'text'} className={cls.btn}>
          Начать обучение <ArrowRightIcon />
        </Button>
      </header>

      <div className={cls.wrapper}>
        {QUESTION_CARD.map((item) => (
          <div key={item.title} className={cls.card}>
            <div className={cls.group}>
              <div className={cls.icon}>{item.icon}</div>
              <Typography
                variant={isDesktop ? 'head-32' : 'head-20'}
                className={cls.card__title}>
                <span
                  dangerouslySetInnerHTML={{ __html: item.title || '' }}></span>
              </Typography>
            </div>
            <Divider className={cls.divider} />
            <ul className={cls.card__list}>
              {item.list.map((text) => (
                <li key={text} className={cls.card__item}>
                  <CheckIcon className={cls.card__check} />
                  <Typography
                    tag={'span'}
                    variant={'body-18'}
                    className={cls.crad__desc}>
                    {text}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};
