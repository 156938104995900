import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const OriginalTelegramLogo: FC<IconProps> = ({
  height = 80,
  width = 81,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <rect y="0.859375" width="80" height="80" rx="40" fill="#28A7E7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6343 39.5304C29.9206 35.0488 36.7798 32.0943 40.2118 30.6668C50.0109 26.591 52.0471 25.883 53.3742 25.8596C53.6661 25.8545 54.3188 25.9268 54.7415 26.2699C55.0985 26.5595 55.1967 26.9508 55.2437 27.2254C55.2907 27.5001 55.3493 28.1257 55.3027 28.6145C54.7717 34.1939 52.474 47.7337 51.3051 53.9828C50.8105 56.627 49.8365 57.5136 48.8937 57.6003C46.8446 57.7889 45.2886 56.2462 43.304 54.9452C40.1984 52.9095 38.444 51.6422 35.4295 49.6557C31.9457 47.36 34.2041 46.0982 36.1895 44.0361C36.7091 43.4964 45.7374 35.2845 45.9121 34.5396C45.934 34.4464 45.9542 34.0991 45.7479 33.9157C45.5416 33.7323 45.2371 33.7951 45.0174 33.8449C44.7059 33.9156 39.7447 37.1948 30.1338 43.6824C28.7256 44.6494 27.4501 45.1205 26.3073 45.0958C25.0474 45.0686 22.624 44.3835 20.8224 43.7978C18.6126 43.0795 16.8564 42.6998 17.0093 41.4799C17.0889 40.8445 17.9639 40.1947 19.6343 39.5304Z"
      fill="white"
    />
  </svg>
);
