import { FC, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import { Button, Divider, Typography } from '@letsdance/ui-kit';
import { MobileLandingLogo } from '@shared/ui/icons/MobileLandingLogo';
import { COURSE_AUTHOR } from '@widgets/Landing/const/main-text.const';
import { ArrowRightIcon, CheckIcon } from '@widgets/Landing/ui/icons';

import cls from './styles.module.scss';

export const CourseAuthors: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <header className={cls.header}>
        <Typography
          variant={isDesktop ? 'head-56' : 'head-32'}
          color={'on-surface-primary-1'}
          className={cls.title}>
          Эксклюзивные курсы <br /> от топовых танцоров
        </Typography>
        <Typography
          variant={isDesktop ? 'body-24' : 'body-16'}
          color={'on-surface-primary-1'}
          className={cls.subtitle}>
          <b>Авторские программы</b> от признанных мастеров сальсы, бачаты{' '}
          {isDesktop && <br />} и кизомбы. Погружайся в мир танца с лучшими!
        </Typography>
        <Button variant={'text'} size={'large'} className={cls.header__btn}>
          Перейти в каталог с курсами <ArrowRightIcon />
        </Button>
      </header>
      <div className={cls.card}>
        <Divider className={cls.divider} />
        {COURSE_AUTHOR.map((item) => (
          <Fragment key={item.name}>
            <div key={item.name} className={cls.wrapper}>
              <div className={cls.imgContainer}>
                <img src={item.avatar} alt={item.name} className={cls.img} />
                {!isDesktop && (
                  <Typography variant={'head-24'}>{item.name}</Typography>
                )}
              </div>
              <div className={cls.group}>
                {isDesktop && (
                  <Typography variant={'head-32'} className={cls.name}>
                    {item.name}
                  </Typography>
                )}
                <div className={cls.description}>
                  {item.description.map((text) => (
                    <div key={text} className={cls.item}>
                      <CheckIcon />
                      <Typography variant={isDesktop ? 'body-18' : 'body-16'}>
                        {text}
                      </Typography>
                    </div>
                  ))}
                </div>
                <Button
                  className={cls.btn}
                  color={'secondary'}
                  onClick={() =>
                    window.open(item.link, '_blank', 'noopener,noreferrer')
                  }>
                  <Typography variant={'head-16'} tag={'span'}>
                    Смотреть курс
                  </Typography>
                </Button>
              </div>
            </div>
            <Divider className={cls.divider} />
          </Fragment>
        ))}
      </div>
      <footer className={cls.footer}>
        <div className={cls.text}>
          <div className={cls.footer__wrapper}>
            <MobileLandingLogo />{' '}
            <Typography
              variant={isDesktop ? 'head-32' : 'head-24'}
              className={cls.footer__title}>
              для профессионалов
            </Typography>
          </div>
          <Typography
            variant={isDesktop ? 'body-24' : 'body-16'}
            className={cls.footer__subtitle}>
            Расширенные возможности для профессиональных
            {isDesktop && <br />} танцоров и преподавателей
          </Typography>
        </div>
        <Button className={cls.btnInfo} onClick={openSupportTg}>
          Узнать больше
        </Button>
      </footer>
    </section>
  );
};
