import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const MobileLandingLogo: FC<IconProps> = ({
  height = 32,
  width = 162,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 162 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <path
      d="M75.1539 30.4407H66.9084V7.60323H75.2221C77.5617 7.60323 79.5758 8.06042 81.2642 8.97482C82.9527 9.88177 84.2513 11.1865 85.1599 12.8889C86.076 14.5913 86.5341 16.6282 86.5341 18.9997C86.5341 21.3786 86.076 23.423 85.1599 25.1328C84.2513 26.8426 82.9451 28.1547 81.2415 29.0691C79.5455 29.9835 77.5163 30.4407 75.1539 30.4407ZM71.8262 26.3037H74.9495C76.4032 26.3037 77.6261 26.0509 78.6179 25.5454C79.6174 25.0324 80.367 24.2407 80.8667 23.1702C81.374 22.0922 81.6277 20.7021 81.6277 18.9997C81.6277 17.3121 81.374 15.9331 80.8667 14.8626C80.367 13.7921 79.6212 13.0041 78.6293 12.4986C77.6374 11.9931 76.4146 11.7403 74.9608 11.7403H71.8262V26.3037Z"
      fill="#404040"
    />
    <path
      d="M94.561 30.7641C93.448 30.7641 92.4561 30.5745 91.5854 30.1954C90.7146 29.8088 90.0256 29.2401 89.5183 28.4893C89.0186 27.731 88.7687 26.7869 88.7687 25.6569C88.7687 24.7053 88.9466 23.9062 89.3025 23.2594C89.6584 22.6126 90.1429 22.0923 90.7562 21.6982C91.3696 21.3042 92.0661 21.0069 92.846 20.8062C93.6335 20.6054 94.4588 20.4642 95.322 20.3824C96.3366 20.2783 97.1543 20.1817 97.7752 20.0925C98.3961 19.9958 98.8466 19.8546 99.1267 19.6687C99.4069 19.4829 99.547 19.2078 99.547 18.8436V18.7767C99.547 18.0704 99.3198 17.524 98.8655 17.1374C98.4188 16.7509 97.7827 16.5576 96.9574 16.5576C96.0867 16.5576 95.3939 16.7471 94.879 17.1263C94.3642 17.498 94.0234 17.9663 93.8568 18.5313L89.382 18.1745C89.6091 17.1337 90.0559 16.2342 90.7222 15.4759C91.3885 14.7102 92.2479 14.1229 93.3003 13.714C94.3604 13.2977 95.587 13.0896 96.9802 13.0896C97.9493 13.0896 98.8769 13.2011 99.7627 13.4241C100.656 13.6471 101.447 13.9928 102.136 14.4612C102.833 14.9295 103.382 15.5317 103.783 16.2676C104.185 16.9962 104.385 17.8697 104.385 18.8882V30.4407H99.7968V28.0655H99.6605C99.3804 28.6008 99.0056 29.0729 98.5361 29.4817C98.0667 29.8832 97.5026 30.1991 96.8439 30.4296C96.1851 30.6526 95.4242 30.7641 94.561 30.7641ZM95.9466 27.4857C96.6584 27.4857 97.2868 27.3481 97.832 27.0731C98.3771 26.7906 98.8049 26.4115 99.1154 25.9357C99.4258 25.4599 99.581 24.9209 99.581 24.3188V22.5011C99.4296 22.5978 99.2214 22.687 98.9564 22.7688C98.6989 22.8431 98.4074 22.9137 98.0818 22.9806C97.7562 23.0401 97.4307 23.0959 97.1051 23.1479C96.7795 23.1925 96.4842 23.2334 96.2192 23.2706C95.6513 23.3523 95.1554 23.4824 94.7314 23.6608C94.3074 23.8393 93.978 24.0809 93.7433 24.3857C93.5086 24.683 93.3912 25.0547 93.3912 25.5008C93.3912 26.1475 93.6297 26.6419 94.1067 26.9839C94.5913 27.3184 95.2046 27.4857 95.9466 27.4857Z"
      fill="#404040"
    />
    <path
      d="M112.477 20.5385V30.4407H107.639V13.3126H112.25V16.3346H112.455C112.841 15.3384 113.488 14.5504 114.397 13.9705C115.305 13.3832 116.407 13.0896 117.702 13.0896C118.913 13.0896 119.97 13.3498 120.871 13.8702C121.772 14.3905 122.472 15.134 122.972 16.1004C123.471 17.0594 123.721 18.2042 123.721 19.5349V30.4407H118.883V20.3824C118.891 19.3342 118.618 18.5165 118.065 17.9292C117.513 17.3344 116.752 17.0371 115.782 17.0371C115.131 17.0371 114.556 17.1746 114.056 17.4497C113.564 17.7247 113.178 18.1262 112.898 18.654C112.625 19.1744 112.485 19.8026 112.477 20.5385Z"
      fill="#404040"
    />
    <path
      d="M134.917 30.7753C133.13 30.7753 131.593 30.4036 130.306 29.6601C129.026 28.9093 128.042 27.8685 127.353 26.5378C126.671 25.2071 126.331 23.6757 126.331 21.9436C126.331 20.1891 126.675 18.6503 127.364 17.327C128.061 15.9963 129.049 14.9592 130.329 14.2158C131.608 13.465 133.13 13.0896 134.894 13.0896C136.416 13.0896 137.749 13.3609 138.892 13.9036C140.035 14.4463 140.94 15.2083 141.607 16.1896C142.273 17.1709 142.64 18.3232 142.708 19.6464H138.143C138.014 18.7915 137.673 18.1039 137.12 17.5835C136.575 17.0557 135.86 16.7918 134.974 16.7918C134.224 16.7918 133.569 16.9925 133.009 17.3939C132.456 17.7879 132.025 18.3641 131.714 19.1223C131.404 19.8806 131.249 20.7987 131.249 21.8767C131.249 22.9695 131.4 23.8987 131.703 24.6644C132.013 25.4302 132.449 26.0137 133.009 26.4152C133.569 26.8166 134.224 27.0173 134.974 27.0173C135.527 27.0173 136.022 26.9058 136.462 26.6828C136.908 26.4598 137.276 26.1364 137.563 25.7126C137.859 25.2815 138.052 24.7648 138.143 24.1626H142.708C142.633 25.471 142.269 26.6233 141.618 27.6195C140.974 28.6082 140.085 29.3814 138.949 29.9389C137.813 30.4965 136.469 30.7753 134.917 30.7753Z"
      fill="#404040"
    />
    <path
      d="M153.257 30.7753C151.462 30.7753 149.917 30.4184 148.623 29.7048C147.335 28.9836 146.344 27.9652 145.647 26.6493C144.95 25.3261 144.602 23.7612 144.602 21.9547C144.602 20.1928 144.95 18.6466 145.647 17.3159C146.344 15.9852 147.324 14.9481 148.589 14.2047C149.861 13.4613 151.352 13.0896 153.063 13.0896C154.214 13.0896 155.286 13.2717 156.278 13.636C157.277 13.9928 158.148 14.5318 158.89 15.2529C159.639 15.974 160.222 16.881 160.639 17.9738C161.055 19.0591 161.264 20.3304 161.264 21.7875V23.0921H146.533V20.1482H156.709C156.709 19.4643 156.558 18.8584 156.255 18.3306C155.952 17.8028 155.532 17.3902 154.994 17.0928C154.464 16.788 153.847 16.6356 153.143 16.6356C152.408 16.6356 151.757 16.8029 151.189 17.1374C150.629 17.4645 150.19 17.9069 149.872 18.4644C149.554 19.0145 149.391 19.6279 149.384 20.3044V23.1033C149.384 23.9508 149.543 24.683 149.861 25.3001C150.186 25.9171 150.644 26.3929 151.235 26.7274C151.825 27.0619 152.526 27.2292 153.336 27.2292C153.874 27.2292 154.366 27.1549 154.812 27.0062C155.259 26.8575 155.642 26.6345 155.96 26.3371C156.278 26.0397 156.52 25.6755 156.686 25.2443L161.161 25.5342C160.934 26.5899 160.469 27.5117 159.764 28.2997C159.068 29.0803 158.167 29.6899 157.061 30.1285C155.963 30.5597 154.695 30.7753 153.257 30.7753Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2521 7.4492V16.9488C14.987 16.5481 12.9515 16.0223 10.5474 16.0223C8.14331 16.0223 6.05243 16.4775 4.89306 16.9395V7.4492H0V31.3249H4.89306V21.354C6.4929 20.8993 8.87798 20.6345 10.5474 20.6345C12.231 20.6345 14.6403 20.9041 16.2521 21.3633V31.3249H21.1476V7.4492H16.2521Z"
      fill="#404040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.3794 16.0913C54.3794 5.92163 48.085 0.86377 39.5026 0.86377H0V5.47145H38.3048C45.1384 5.47145 49.4939 8.79348 49.4939 16.0913C49.4939 23.3844 45.1384 26.7109 38.3048 26.7109H27.8439C29.9826 23.9385 34.5312 22.4797 37.1213 20.0701C38.6461 18.6517 39.8723 16.7537 39.8723 14.172C39.8723 11.6723 38.701 9.74142 36.9948 8.50093C35.37 7.31895 33.8071 6.82041 31.9509 6.82041C27.4341 6.82041 22.6716 10.0503 22.6716 16.0959H27.5486C27.5486 13.0883 29.708 11.4259 31.9509 11.4259C33.4528 11.4259 34.9956 12.5879 34.9956 14.1744C34.9956 16.4151 32.1067 18.1683 30.3951 19.1644C26.5972 21.3998 22.7314 24.11 22.7314 28.929L22.686 31.3186H38.3024C48.2043 31.3186 54.3794 26.2608 54.3794 16.0913Z"
      fill="#FF2F6C"
    />
  </svg>
);
