import { FC } from 'react';

import { Typography } from '@letsdance/ui-kit';
import { STICKERS_CARD } from '@widgets/Landing/const/main-text.const';
import { OriginalTelegramLogo } from '@widgets/Landing/ui/icons';

import cls from './styles.module.scss';

export const StickersCard: FC = () => (
  <div className={cls.container}>
    <div className={cls.wrapper}>
      {STICKERS_CARD.map((item) => (
        <div key={item.title} className={cls.item}>
          {item.icon ? (
            <div className={cls.icon}>{item.icon}</div>
          ) : (
            <OriginalTelegramLogo className={cls.telegram} />
          )}
          <Typography variant={'head-16'} className={cls.text}>
            <span
              dangerouslySetInnerHTML={{
                __html: item.title || '',
              }}></span>
          </Typography>
        </div>
      ))}
    </div>
  </div>
);
